/**
 * Calculate the average review rating from a review histogram.
 * Returns 0 if the histogram is empty or undefined.
 * @param reviewHistogram
 */
export function reviewAverage(reviewHistogram: Array<{ bin: number, frequency: number }>): number {
    if (!reviewHistogram || reviewHistogram.length === 0) {
        return 0;
    }
    const total = reviewHistogram.reduce((acc, { frequency }) => acc + frequency, 0);
    const weightedSum = reviewHistogram.reduce((acc, { bin, frequency }) => acc + bin * frequency, 0);
    return weightedSum / total;
}
